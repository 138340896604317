import { format, parseISO } from "date-fns";
import { enGB } from "date-fns/locale";

export const scrollToRef = (ref) =>
  ref.current.scrollIntoView({ behavior: "smooth" });

export const isNil = <T>(
  item: T | null | undefined
): item is null | undefined => item == null;

export const isNotNil = <T>(item: T | null | undefined): item is T =>
  !isNil(item);

export const roundUpToNearest5 = (number) => {
  return Math.ceil(number / 5) * 5;
};

/**
 * Function to clamp a number between 2 values (min and max).
 *
 * @param num The number you want to clamp.
 * @param min The minimum value the number can be.
 * @param max The max value the number could be.
 *
 * @returns The clamped number value.
 */
export const clamp = (num: number, min = 0, max = 5) => {
  return Math.min(Math.max(num, min), max);
};

export const slugify = (str: string) => {
  return str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, "")
    .replace(/[\s_-]+/g, "-")
    .replace(/^-+|-+$/g, "");
};

export const wait = (ms: number) => new Promise((res) => setTimeout(res, ms));
/**
 * Function to format an ISO date string.
 *
 * @param date The ISO date string to be formatted.
 * @param structure The structure used when formatting the date [see here](https://date-fns.org/v2.30.0/docs/format)
 * @param options
 * @returns The formatted ISO string.
 */
export function formatISOString(
  date: string,
  structure: string,
  options?: Parameters<typeof format>["2"]
) {
  const parsedDate = parseISO(date);

  return format(parsedDate, structure, { locale: enGB, ...options });
}
