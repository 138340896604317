import classNames from "classnames";
import React from "react";

import { StarRating } from "../shared/StarRating";

interface TestimonialCardProps {
  name: string;
  quote: string;
  rating?: number;
  image?: string;
}

export interface TestimonialProps {
  testimonials: TestimonialCardProps[];
  className?: string;
}
export const Testimonials = ({
  testimonials = [],
  className,
}: TestimonialProps) => {
  return (
    <div
      className={classNames(
        "flex snap-x snap-mandatory scroll-p-4 gap-4 overflow-x-auto p-5 xl:justify-center",
        className
      )}
    >
      {testimonials.map(({ name, quote, rating, image }) => (
        <TestimonialCard
          key={name + quote}
          name={name}
          quote={quote}
          rating={rating}
          image={image}
        />
      ))}
    </div>
  );
};

function TestimonialCard({ name, quote, rating, image }: TestimonialCardProps) {
  return (
    <div className="flex min-w-[min(calc(100%-20px),400px)] max-w-[400px] snap-center items-start gap-x-4 rounded-xl border border-neutral-200 bg-white p-5 shadow-sm">
      {image && (
        <img
          alt={`Headshot of ${name}`}
          className="h-14 w-14 shrink-0 rounded-full object-cover"
          loading="lazy"
          src={image}
        />
      )}

      <blockquote className="w-full">
        <div className="mb-1 flex items-center justify-between">
          <div className="font-maison-extended text-sm font-bold leading-[1.6] text-neutral-900">
            {name}
          </div>
          <StarRating rating={rating} className="h-4.5" />
        </div>
        <p className="text-xs font-normal leading-[1.65] text-neutral-950">
          &ldquo;{quote}&rdquo;
        </p>
      </blockquote>
    </div>
  );
}
