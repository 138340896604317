import { createRoot } from "react-dom/client";
import * as React from "react";
import { TestimonialProps, Testimonials } from "../src/UI/Testimonials";

document.addEventListener("DOMContentLoaded", () => {
  const node = document.getElementById("testimonials");
  if (!node) {
    return;
  }

  const root = createRoot(node);

  const data = JSON.parse(node.getAttribute("data") || "{}");

  const className: string = data.classes || "";

  const testimonials: TestimonialProps["testimonials"] = data?.testimonials
    ? data.testimonials.map(mapTestimonial)
    : getDefaultTestimonials();

  root.render(
    <Testimonials testimonials={testimonials} className={className} />
  );
});

interface RawTestimonial {
  name: string;
  image?: string;
  quote: string;
  rating?: string;
  location?: string;
}
function mapTestimonial(testimonial: RawTestimonial) {
  return {
    name: (testimonial.name || "") as string,
    quote: (testimonial.quote || "") as string,
    image: (testimonial?.image || "") as string,
    location: (testimonial?.location || "") as string,
    rating: Number(testimonial?.rating || "5"),
  };
}

function getDefaultTestimonials() {
  return [
    {
      name: "Rhianne",
      location: "Birmingham",
      quote:
        "Can't recommend them highly enough. Half the cost of the big private names and scan booked, completed and results back in very quickly. Clinician giving the results gave sound practical follow on advice.",
      rating: 5,
      image: "/images/testimonials/rhianne.jpg",
    },
    {
      name: "Maureen",
      location: "Chelmsford",
      quote:
        "I booked a private scan over concern about headaches. The head & spine scan turned out to be fine. Treatment from booking to contact was efficient, courteous & friendly in all departments.",
      rating: 5,
      image: "/images/testimonials/maureen.jpg",
    },
    {
      name: "Torill",
      location: "Colchester",
      quote:
        "Quite EXCELLENT The whole process from booking to having the MRI images and the radiologists report in the right hands could not have been faster. The price was hugely competitive too!",
      rating: 5,
      image: "/images/testimonials/toril.jpg",
    },
  ];
}
