import classNames from "classnames";
import React from "react";
import { IconStar } from "../Icons/IconStar";
import { clamp } from "../utils";

interface StarRatingProps {
  rating?: number;
  max?: number;
  min?: number;
  className?: string;
}

export function StarRating({
  min = 1,
  max = 5,
  rating = 0,
  className,
}: StarRatingProps) {
  const clampedRating = clamp(Math.round(rating), min, max);

  const label = `Rating: ${rating} out of ${max} stars`;
  const wrapperStyles = classNames("flex", className);

  return (
    <div className={wrapperStyles} role="img" aria-label={label}>
      {[...Array(clampedRating)].map((_, i) => (
        <IconStar
          key={i}
          aria-hidden
          loading="lazy"
          className="aspect-square h-full"
        />
      ))}
    </div>
  );
}
